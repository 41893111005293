import { h } from 'preact';
import { Route, Router } from 'preact-router';
import Register from '../routes/register';


const App = () => (
    <div id="app">
        <div class="container">
            <div class="row">
                <div class="twelve columns">
                    <span class="mainText">
                        <img src="/assets/img/Logo.svg"></img>
                    </span>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="one column filler">&nbsp;</div>
                <div class="ten columns">
                    <div class="mainPanel">
                        <div style="text-align:center; margin-bottom:35px;">
                            <span class="mainTextTop">Oratorium Bachledowiańskie</span><br />
                            <span class="mainText">Równoj ku górze</span><br />
                            <span class="mainText2">ku pamięci Prymasa Tysiąclecia</span><br />

                            <div style="margin-top: 30px; font-weight:600;"><span class="colorGold">Kiedy?</span> 10 Grudnia 2022 godzi. 18.00</div>
                            <div class="fontPoppins500"><span class="colorGold fontPoppins600">Gdzie?</span> Kościół Świętego Krzyża, ul. Chałubińskiego 30, Zakopane</div>
                        </div>
                        {/* <Router>
                            <Route path="/" component={Register} />
                        </Router> */}
                        <div style="text-align:center; margin-top:50px; margin-bottom:50px;">
                        <span class="mainText" style="font-size:30px;">Rejestracja zakończona</span><br />
                        </div>
                    </div>
                </div>
                <div class="one column filler">&nbsp;</div>
            </div>
            <div class="row" style="margin-top:15px;">
                <div class="one column">
                    &nbsp;
                </div >
                <div class="four columns">
                    <span class="footer">
                        Kontakt:<br />
                        NetSoftware <br />
                        Ul.Różana 19/12<br />
                        30-305 Kraków<br />
                        koncert@rownojkugorze.pl<br />
                    </span>
                </div>
                <div class="four columns">
                    <span class="footer">
                        Kontakt:<br />
                        Centrum Kultury i Promocji Gminy Czarny Dunajec<br />
                        34-470 Czarny Dunajec<br />
                        ul. Józefa Piłsudskiego 2A<br />
                        ckip@czarny-dunajec.pl<br />
                    </span>
                </div>
                <div class="three columns">
                    <span class="footer">
                        <a href="/assets/doc/Zasady_uczestnictwa.pdf" class="lightHref" target="_blank">Zasady uczestnictwa</a><br />
                        <a href="http://rownojkugorze.pl/polityka-prywatnosci/" class="lightHref" target="_blank">Polityka prywatności</a><br />
                        <a href="http://rownojkugorze.pl/regulamin-sklepu/" class="lightHref" target="_blank">Regulamin sklepu</a><br />
                        <a href="http://rownojkugorze.pl/dostawa/" class="lightHref" target="_blank">Dostawa</a><br />
                        <a href="http://rownojkugorze.pl/platnosci/" class="lightHref" target="_blank">Płatność</a><br />
                        <a href="http://rownojkugorze.pl/kontakt/" class="lightHref" target="_blank">Kontakt</a>

                    </span>
                </div>
            </div>
        </div>
        {/* </div> */}
    </div>
);


export default App;
